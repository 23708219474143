import React, { useState } from "react";
import { connect } from "react-redux";
import { appUIPopupMasFiltrosHide } from "../../redux/app/actions";
import { Range, getTrackBackground } from "react-range";

const step = 1;
const minRent = 0;
const maxRent = 30;

const PopupMasFiltros = ({
	popupMasFiltros,
	appUIPopupMasFiltrosHide,
	tablas,
	setOperacion,
	setTabla,
	setDesde,
	setHasta,
	operacion,
	tabla,
	desde,
	hasta,
	onAccept,
	s,
}) => {
	return (
		<article className={`popup popup-masfiltros ${popupMasFiltros && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupMasFiltrosHide}></span>
			<div className="wrapper white-bg">
				<div className="box">
					<span
						className="close-popup close-button black-bg-01"
						onClick={() => {
							appUIPopupMasFiltrosHide();
							onAccept();
						}}
					></span>
					<h3 className="show-lg">Más filtros</h3>
					<h3 className="hide-lg">Filtros</h3>

					<div className="filterType hide-lg">
						<h4>Tipo de operación</h4>
						<ul className="filter-list">
							<li className={operacion === null && "active"}>
								<button onClick={() => setOperacion(null)}>Todas</button>
							</li>
							<li className={operacion === "alquiler" && "active"}>
								<button onClick={() => setOperacion("alquiler")}>Sólo alquiler</button>
							</li>
							<li className={operacion === "venta" && "active"}>
								<button onClick={() => setOperacion("venta")}>Sólo venta</button>
							</li>
							<li className={operacion === "alquilerventa" && "active"}>
								<button onClick={() => setOperacion("alquilerventa")}>Alquiler y venta</button>
							</li>
						</ul>
					</div>

					<div className="filterType">
						<h4>Tipo de tabla</h4>
						<ul className="filter-list">
							{tablas.map((t, i) => {
								return (
									<li className={t.id === parseInt(tabla) ? `active` : ``} key={i}>
										<button onClick={() => setTabla(t.id)}>{t.title}</button>
									</li>
								);
							})}
							<li className={tabla === null && "active"}>
								<button onClick={() => setTabla(null)}>Todas</button>
							</li>
						</ul>
					</div>
					<div className="filterType">
						<h4>Precio</h4>

						{/* RENT RANGE SLIDER */}
						<div className="rangeWrapper">
							<span className="rangeLabel">Alquiler (€/hora)</span>
							<div className="innerRangeWrapper">
								<Range
									className="myRange"
									values={[desde !== null ? desde : minRent, hasta !== null ? hasta : maxRent]}
									step={step}
									min={minRent}
									max={maxRent}
									onChange={(values) => {
										setDesde(values[0]);
										setHasta(values[1]);
									}}
									renderTrack={({ props, children }) => (
										<>
											<div
												onMouseDown={props.onMouseDown}
												onTouchStart={props.onTouchStart}
												className="innerTrackWrapper"
												style={{
													...props.style,
												}}
											>
												<div
													ref={props.ref}
													style={{
														background: getTrackBackground({
															values: [
																desde !== null ? desde : minRent,
																hasta !== null ? hasta : maxRent,
															],
															colors: ["#DDDDDD", "#BDBDBD", "#DDDDDD"],
															min: minRent,
															max: maxRent,
														}),
													}}
													className="innerTrack"
												>
													{children}
												</div>
											</div>

											<output id="output" className="rangeOutput">
												<span>{desde !== null ? desde : minRent}€</span>
												<span>{hasta !== null ? hasta : maxRent}€</span>
											</output>
										</>
									)}
									renderThumb={({ props, isDragged }) => (
										<div
											{...props}
											style={{
												...props.style,
											}}
											className="rangeThumbWrapper"
										>
											<div
												style={{
													backgroundColor: isDragged ? "#000000" : "#4F4F4F",
												}}
												className="rangeThumb"
											/>
										</div>
									)}
								/>
							</div>
						</div>
						{/* RENT RANGE SLIDER */}
					</div>
					<div className="button-wrapper">
						<button
							className="button black-bg-01 white-text"
							onClick={() => {
								appUIPopupMasFiltrosHide();
								onAccept();
							}}
						>
							Aplicar
						</button>
					</div>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupMasFiltros } = state.app.ui;
	const { tablas } = state.app.boot;
	return {
		popupMasFiltros,
		tablas,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupMasFiltrosHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupMasFiltros);

import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";

const Ad = ({ imgDesktop, imgMobile, title, text, linkTo, linkText, external, s }) => {
	const getLink = (content) => {
		if (external)
			return (
				<a href={linkTo} target="_blank" className={styles.item}>
					{content}
				</a>
			);
		else
			return (
				<Link to={linkTo} className={styles.item}>
					{content}
				</Link>
			);
	};
	return getLink(
		<>
			{
				(imgDesktop,
				imgMobile && (
					<div className={styles.wrapperImg}>
						<picture>
							<source srcSet={imgDesktop} media="(min-width: 992px)" />
							<img srcSet={imgMobile} />
						</picture>
					</div>
				))
			}
			<div className={`${styles.wrapperText} ${(!imgDesktop, !imgMobile && styles.withoutImg)}`}>
				<h3>{title}</h3>
				<p>{text}</p>
				<span>{linkText}</span>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Ad);

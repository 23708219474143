import React from "react";
import { connect } from "react-redux";
import { appUIPopupOrdenarHide } from "../../redux/app/actions";

const PopupOrdenar = ({ sortOptions, orden, setOrden, onAccept, popupOrdenar, appUIPopupOrdenarHide, s }) => {
	return (
		<article className={`popup popup-ordenar ${popupOrdenar && `show`}`}>
			<span
				className="close-popup overlay show-lg"
				onClick={() => {
					appUIPopupOrdenarHide();
					onAccept();
				}}
			></span>
			<div className="wrapper white-bg">
				<div className="box">
					<span className="close-popup close-button black-bg-01" onClick={appUIPopupOrdenarHide}></span>
					<h3>Ordenar por</h3>
					<div className="filterType hide-lg">
						<ul className="filter-list">
							{Object.keys(sortOptions).map((key, index) => {
								return (
									<li className={`checkboxes ${orden === sortOptions[key].value && "active"}`} key={index}>
										<label htmlFor={key}>
											<em>{sortOptions[key].label}</em>
											<input
												type="checkbox"
												id={key}
												name={key}
												checked={orden === sortOptions[key].value}
												onClick={() => setOrden(sortOptions[key].value)}
											/>
										</label>
										<div className="design"></div>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="button-wrapper">
						<button
							onClick={() => {
								appUIPopupOrdenarHide();
								onAccept();
							}}
							className="button black-bg-01 white-text"
						>
							Aplicar
						</button>
					</div>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupOrdenar } = state.app.ui;
	return {
		popupOrdenar,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupOrdenarHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupOrdenar);

import React, { useEffect, useRef, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import {
	appUIPopupMasFiltrosShow,
	appUIPopupMasFiltrosHide,
	appUIPopupOrdenarShow,
	appUIPopupOrdenarHide,
	appGeoSet,
} from "../redux/app/actions";
import { anunciosGetAjenos, anunciosResetAjenos, anuncioHotelesGet } from "../redux/anuncios/actions";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import PropTypes from "prop-types";
import PopupMasFiltros from "../components/popupMasFiltros";
import PopupOrdenar from "../components/popupOrdenar";
import Ad from "../components/Ad";
import Lodge from "../components/Lodge";
import withPathPairs from "../hocs/withPathPairs";
import _ from "lodash";
import Slider from "react-slick";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import { useStaticQuery, graphql } from "gatsby";
import ReactLoading from "react-loading";
import Tools from "../classes/tools";
import InfiniteScroll from "react-infinite-scroller";
import SliderCustom from "../components/common/SliderCustom";

const Anuncios = ({
	appUIPopupMasFiltrosShow,
	appUIPopupOrdenarShow,
	anunciosGetAjenos,
	anunciosResetAjenos,
	anuncioHotelesGet,
	anuncios,
	loading,
	pagination,
	urlVars,
	bootLoaded,
	areas,
	anuncioHoteles,
	geo,
	appGeoSet,
	s,
}) => {
	const intl = useIntl();

	var planSlider = useRef(null);

	const planSliderDetails = [
		{
			image: "/img/planes-mediterraneo.jpg",
			title: "Surfing en el Mediterráneo",
			text: "Conoce los mejores spots del Mediterráneo y organiza tu viaje de surfing.",
			sea: "Mediterráneo",
			linkTo: "/paginas/viajes-surfing-mediterraneo",
			linkText: "Saber más",
		},
	];

	var sliderHotels = useRef(null);

	const customSettings = {
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 1279,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					variableWidth: true,
				},
			},
		],
	};

	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#cccccc" : "#f2f2f2",
				fontSize: 14,
				color: "#4F4F4F",
			};
		},
		menu: (base) => ({
			...base,
			borderRadius: 0,
			marginTop: "1px",
		}),
		menuList: (base) => ({
			...base,
			padding: 0,
			borderRadius: 0,
		}),
		singleValue: (base) => ({
			...base,
			fontSize: 14,
			color: "#4F4F4F",
		}),
	};

	const { hoteles } = useStaticQuery(graphql`
		query {
			hoteles: allInternalHoteles(filter: { alternative_id: { ne: null } }) {
				nodes {
					id: alternative_id
					imagen_header_mobile {
						url
					}
					intl {
						title {
							en
							es
						}
						excerpt {
							en
							es
						}
					}
					name
					localizaciones {
						id: alternative_id
						zona {
							id: alternative_id
						}
						pais {
							id: alternative_id
						}
					}
				}
			}
		}
	`);

	const [mode, setMode] = useState(null);
	const [area, setArea] = useState(null);
	// const [lat, setLat] = useState(null);
	// const [long, setLong] = useState(null);
	// const [geoDescripcion, setGeoDescripcion] = useState(null);
	const [operacion, setOperacion] = useState(null);
	const [tabla, setTabla] = useState(null);
	const [desde, setDesde] = useState(null);
	const [hasta, setHasta] = useState(null);
	const [orden, setOrden] = useState(null);
	const [page, setPage] = useState(null);

	const [work, setWork] = useState(false);

	useEffect(() => {
		if (bootLoaded) {
			anunciosResetAjenos();
			if (urlVars.mode === "area" && urlVars.area !== "-") {
				setMode("area");
				setArea(Tools.findAreaById(urlVars.area, areas));
			} else {
				setArea(null);
				setMode("geo");
				const { lat, long, geo_descripcion } = urlVars;
				if (lat && lat !== "-" && long && long !== "-" && geo_descripcion && geo_descripcion !== "-") {
					appGeoSet(lat, long, decodeURI(geo_descripcion));
				}
			}
			setOperacion(urlVars.operacion || null);
			setTabla(urlVars.tabla || null);
			setDesde(urlVars.desde || null);
			setHasta(urlVars.hasta || null);
			setOrden(urlVars.orden || "recientes");
			setPage(1);
			setWork(true);
		}
	}, [bootLoaded]);

	useEffect(() => {
		if (work) {
			anunciosGetAjenos(intl.locale, null, {
				mode,
				operacion,
				tabla,
				desde,
				hasta,
				orden,
				area: area ? area.id : null,
				page,
				lat: geo.lat,
				long: geo.long,
				limit: 12,
			});
			setWork(false);
		}
	}, [work]);

	useEffect(() => {
		if (mode === "area") {
			anunciosResetAjenos();
			setMode("geo");
			setArea(null);
			setPage(1);
			setWork(true);
			renavigate({
				mode: "geo",
				area: null,
			});
		}
	}, [geo.lat, geo.long]);

	const loadMore = () => {
		if (!loading) {
			setPage(page + 1);
			setWork(true);
		}
	};

	const renavigate = (params = {}) => {
		navigate(
			`/anuncios/${Tools.buildUrlPath({
				mode: mode || "-",
				operacion: operacion || "-",
				tabla: tabla || "-",
				desde: desde || "-",
				hasta: hasta || "-",
				orden: orden || "-",
				area: area ? area.id : "-",
				...params,
			})}`
		);
	};

	const onHeaderGeoLocationChange = (data) => {
		anunciosResetAjenos();
		setArea(null);
		setMode("geo");
		appGeoSet(data.lat, data.long, data.descripcion);
		renavigate({
			mode: "geo",
			lat: data.lat,
			long: data.long,
			geo_descripcion: data.descripcion,
		});
		setWork(true);
	};

	const acceptMasFiltros = () => {
		anunciosResetAjenos();
		setPage(1);
		setWork(true);
		renavigate();
	};

	const acceptOrdenar = () => {
		anunciosResetAjenos();
		setPage(1);
		setWork(true);
		renavigate();
	};

	useEffect(() => {
		if (mode === "area") {
			anuncioHotelesGet(intl.locale, {
				mode: "area",
				area: area.id,
				orden: "distancia",
				page: 1,
				limit: 10,
			});
		}
	}, [mode]);

	const [hotelesSliderItems, setHotelesSliderItems] = useState(null);

	const adsDetails = [
		{
			imgDesktop: "/img/anuncios-ad1-desktop.jpg",
			imgMobile: "/img/anuncios-ad1-mobile.jpg",
			title: "Surfing en el Mediterráneo",
			text: "Descubre los principales spots del Mediterráneo y organiza tu viaje de surfing.",
			linkTo: "/paginas/viajes-surfing-mediterraneo",
			linkText: "Saber más",
			external: false,
		},
		{
			title: "Alquilar una tabla en Share Your Board",
			text: "Te damos 5 razones por las que alquilar una tabla directamente a otro surfista.",
			linkTo: "/porquealquilar",
			linkText: "Saber más",
			external: false,
		},
		{
			title: "¿Quieres promocionar tu marca en Share Your Board?",
			text: "Si tienes un producto relacionado con el surf, queremos colaborar contigo.",
			linkTo: "/paginas/publicidad",
			linkText: "Saber más",
			external: false,
		},
	];

	// sort options

	const [sortOptions, setSortOptions] = useState({});

	useEffect(() => {
		switch (mode) {
			case "area":
				setSortOptions({
					menorprecio: {
						label: "Menor precio",
						value: "menorprecio",
					},
					mayorprecio: {
						label: "Mayor precio",
						value: "mayorprecio",
					},
					recientes: {
						label: "Más recientes",
						value: "recientes",
					},
					antiguos: {
						label: "Más antiguos",
						value: "antiguos",
					},
				});
				break;
			default:
				setSortOptions({
					distancia: {
						label: "Más cercanos",
						value: "distancia",
					},
					menorprecio: {
						label: "Menor precio",
						value: "menorprecio",
					},
					mayorprecio: {
						label: "Mayor precio",
						value: "mayorprecio",
					},
					recientes: {
						label: "Más recientes",
						value: "recientes",
					},
					antiguos: {
						label: "Más antiguos",
						value: "antiguos",
					},
				});
				break;
		}
	}, [mode]);

	return (
		<>
			<PopupMasFiltros
				setOperacion={setOperacion}
				setTabla={setTabla}
				setDesde={setDesde}
				setHasta={setHasta}
				operacion={operacion}
				tabla={tabla}
				desde={desde}
				hasta={hasta}
				onAccept={acceptMasFiltros}
			/>
			<PopupOrdenar sortOptions={sortOptions} orden={orden} setOrden={setOrden} onAccept={acceptOrdenar} />
			<BodyClassName className="results">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Tablas de surf disponibles" />
					<main className="page">
						<article className="box items">
							<Link to="/" className="breadcrumbs">
								<ReactSVG src="/img/arrow.svg" wrapper="span" />
								Home
							</Link>
							{mode === "area" && area && area.title && <h3 className="boards-title">Tablas en {area.title}</h3>}
							<div className="filtersWrapper white-bg">
								<ul className="results-filter">
									<li className={`show-lg ${operacion === null && "active"}`}>
										<button
											onClick={() => {
												anunciosResetAjenos();
												setOperacion("-");
												setPage(1);
												setWork(true);
												renavigate({
													operacion: null,
												});
											}}
										>
											Todas
										</button>
									</li>
									<li className={`show-lg ${operacion === "alquiler" && "active"}`}>
										<button
											onClick={() => {
												anunciosResetAjenos();
												setOperacion("alquiler");
												setPage(1);
												setWork(true);
												renavigate({
													operacion: "alquiler",
												});
											}}
										>
											Sólo alquiler
										</button>
									</li>
									<li className={`show-lg ${operacion === "venta" && "active"}`}>
										<button
											onClick={() => {
												anunciosResetAjenos();
												setOperacion("venta");
												setPage(1);
												setWork(true);
												renavigate({
													operacion: "venta",
												});
											}}
										>
											Sólo venta
										</button>
									</li>
									<li className={`show-lg ${operacion === "alquilerventa" && "active"}`}>
										<button
											onClick={() => {
												anunciosResetAjenos();
												setOperacion("alquilerventa");
												setPage(1);
												setWork(true);
												renavigate({
													operacion: "alquilerventa",
												});
											}}
										>
											Alquiler y venta
										</button>
									</li>
									<li>
										<span onClick={appUIPopupMasFiltrosShow}>
											<em className="show-lg">Más filtros</em>
											<em className="hide-lg">Filtros</em>
										</span>
									</li>
									<li className="hide-lg">
										<span onClick={appUIPopupOrdenarShow}>Ordenar</span>
									</li>
								</ul>
								<div className="orderBy show-lg">
									<p>Ordenar por</p>
									<Select
										className="filterSelect"
										classNamePrefix="Select"
										components={{ IndicatorSeparator: () => null }}
										options={(() => {
											const options = [];
											Object.keys(sortOptions).forEach((key) => {
												options.push(sortOptions[key]);
											});
											return options;
										})()}
										defaultValue={orden ? sortOptions[orden] : null}
										value={orden ? sortOptions[orden] : null}
										styles={colourStyles}
										onChange={(e) => {
											anunciosResetAjenos();
											setOrden(e.value);
											setPage(1);
											setWork(true);
											renavigate({
												orden: e.value,
											});
										}}
									/>
								</div>
							</div>

							<InfiniteScroll
								hasMore={pagination.page < pagination.pages}
								initialLoad={false}
								loadMore={!loading && loadMore}
							>
								{page === 1 && loading && (
									<div className="loadingWrapper">
										<ReactLoading type="balls" color="black" width="64px" height="64px" />
									</div>
								)}
								<div className="resultsWrapper">
									<div className="items-wrapper">
										{anuncios.length > 0 &&
											anuncios.map((anuncio) => {
												return (
													<div className="slider slider-boards" key={anuncio.id}>
														<div className="slider-boards-item">
															<div className="wrapper">
																{/* favorito */}
																{/* <span className="favorite-icon"></span> */}
																<Link
																	to={`/veranuncio/${anuncio.id}`}
																	className="slider-item-boards-wrapper anuncios_tablas"
																>
																	{/* imagen */}
																	{anuncio.imagenes && anuncio.imagenes.length > 0 && (
																		<img
																			className="board-img"
																			src={anuncio.imagenes[0].url}
																			alt={anuncio.title}
																			loading="lazy"
																		/>
																	)}
																	{!anuncio.imagenes ||
																		(anuncio.imagenes.length === 0 && (
																			<img
																				className="board-img"
																				src="/img/anuncio-sin-imagenes.png"
																				alt={anuncio.title}
																			/>
																		))}
																</Link>
															</div>
															<Link
																to={`/veranuncio/${anuncio.id}`}
																className="slider-boards-details anuncios_tablas"
															>
																<div className="wrapperTitles">
																	{/* title */}
																	{anuncio.title && (
																		<h4
																			className="black-text-01 description"
																			dangerouslySetInnerHTML={{ __html: anuncio.title }}
																		/>
																	)}
																	{/* distancia */}
																	{/* {(geo.lat && geo.long && anuncio.distancia) && ( */}
																	{geo.lat !== null &&
																		geo.long !== null &&
																		anuncio.distancia !== "" &&
																		anuncio.distancia !== null && (
																			<p className="distance">
																				A <em>{anuncio.distancia}</em> km
																			</p>
																		)}
																	{/* )} */}
																</div>

																{/* Precios de alquiler y venta */}

																{(anuncio.operacion === 1 || anuncio.operacion === 3) && (
																	<p>Alquiler: {anuncio.precio_alquiler}€/hora</p>
																)}
																{(anuncio.operacion === 2 || anuncio.operacion === 3) && (
																	<p>Venta: {anuncio.precio_venta}€</p>
																)}

																{/* Puntaje */}

																{/* <small className="lightgray-text-05 rate">
																<img src="/img/rate.svg" />
																4,5<span>(21)</span>
															</small> */}
															</Link>
														</div>
													</div>
												);
											})}

										{/* Fin de anuncios */}
										{!loading && (anuncios.length === 0 || pagination.pages <= page) && (
											<p className="lightgray-text-02 no-posts align-center">
												No hay más anuncios para mostrar.
											</p>
										)}
									</div>

									{/* Ads */}

									<div className="adsWrapper">
										{adsDetails.map((adsDetail, index) => (
											<Ad
												key={index}
												imgDesktop={adsDetail.imgDesktop}
												imgMobile={adsDetail.imgMobile}
												title={adsDetail.title}
												text={adsDetail.text}
												linkTo={adsDetail.linkTo}
												linkText={adsDetail.linkText}
												external={adsDetail.external}
											/>
										))}
									</div>
								</div>

								{/* Loading sign */}
								{loading && page > 1 && (
									<div className="loadingWrapper">
										<ReactLoading type="balls" color="black" width="64px" height="64px" />
									</div>
								)}
							</InfiniteScroll>
						</article>

						{/* WHAT TO DO */}
						{planSliderDetails && (
							<SliderCustom
								title="Experiencias en el Cantábrico"
								text="Si aún no sabes donde ir, te damos algunos consejos"
								items={planSliderDetails}
								typeContent="lodge"
								bgColor="#f1f1f1"
								paddingTop={80}
								paddingBottom={100}
							/>
						)}
						{/* WHAT TO DO */}

						{/* SLIDER HOTELES/LODGE */}
						{/*{planSliderDetails && (*/}
						{/*	<SliderCustom*/}
						{/*		title="Alojamientos en el Cantábrico"*/}
						{/*		text="Colaboramos con una red de alojamientos para hacértelo más fácil. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna."*/}
						{/*		items={planSliderDetails}*/}
						{/*		typeContent="lodge"*/}
						{/*	/>*/}
						{/*)}*/}
						{/* SLIDER HOTELES/LODGE */}

						{/* {anuncioHoteles && anuncioHoteles.length > 0 && (
								<>
									<article className="hotels box white-bg">
										<div className="wrapper">
											<h4 className="prequel-font black-text-01">HOTELES / EXPERIENCIAS</h4>
											<div className="slider slider-hotels">
												<Slider
													ref={(c) => (sliderHotels = c)}
													speed={500}
													infinite={false}
													arrows={false}
													slidesToShow={3}
													slidesToScroll={1}
													adaptiveHeight={true}
													responsive={[
														{
															breakpoint: 1199,
															settings: {
																slidesToShow: 2,
																slidesToScroll: 2,
															},
														},
														{
															breakpoint: 767,
															settings: {
																slidesToShow: 1,
																slidesToScroll: 1,
															},
														},
													]}
												>
													{anuncioHoteles.map((hotel) => {
														return (
															<Link
																to={`/hoteles/${hotel.name}`}
																className="slider-hotels-item"
																key={hotel.id}
															>
																<div className="wrapper">
																	<div
																		className="slider-item-hotels-wrapper"
																		style={{
																			backgroundImage: `url('${hotel.imagen_header_mobile.url}')`,
																		}}
																	/>
																	<div className="slider-hotels-details black-bg-01">
																		<p
																			className="hotel-title white-text"
																			dangerouslySetInnerHTML={{ __html: hotel.title }}
																		/>
																		<p
																			className="hotel-description lightgray-text-08"
																			dangerouslySetInnerHTML={{ __html: hotel.excerpt }}
																		/>
																	</div>
																</div>
															</Link>
														);
													})}
												</Slider>
												<div className="slider-arrows">
													<span className="arrow left" onClick={() => sliderHotels.slickPrev()}></span>
													<span className="arrow right" onClick={() => sliderHotels.slickNext()}></span>
												</div>
											</div>
										</div>
									</article>
									<article className="hotels box white-bg">
										<p style={{ textAlign: "center" }}>
											¿Tienes un hotel y te gustaría incluirlo en esta sección gratis? Escríbenos a{" "}
											<a href="mailto:hello@shareyourboard.app">hello@shareyourboard.app</a>.
										</p>
										<br />
										<br />
										<br />
									</article>
								</>
							)} */}

						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

Anuncios.propTypes = {
	urlVars: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { paises, loaded: bootLoaded, areas } = state.app.boot;
	const { loggedIn, resolved: authResolved } = state.auth;
	const { ajenos: anuncios, ajenosLoading: loading, ajenosPagination: pagination, anuncioHoteles } = state.anuncios;
	const { geo } = state.app;
	return {
		paises,
		bootLoaded,
		areas,
		loggedIn,
		authResolved,
		anuncios,
		loading,
		pagination,
		anuncioHoteles,
		geo,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupMasFiltrosShow,
	appUIPopupMasFiltrosHide,
	appUIPopupOrdenarShow,
	appUIPopupOrdenarHide,
	anunciosGetAjenos,
	anunciosResetAjenos,
	anuncioHotelesGet,
	appGeoSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPathPairs(Anuncios));
